<template>
  <section
    :class="$customSectionClasses(props.cfgs)"
    class="basicInfo-block"
    data-key-anchor="Основная информация о проекте (страница проекта)"
  >
    <div class="container">
      <div class="basicInfo-block-content">
        <div class="left">
          <div class="h2">{{ props.values.title }}</div>
          <div
            class="selector-block-subtitle"
            v-html="props.values.subtitle"
          ></div>
          <div
            class="selector-block-description"
            v-html="props.values.description"
          ></div>
        </div>
        <div class="right">
          <BasicInformationPriceList
            v-if="values?.additional_information"
            class="price-list"
            :values="values?.additional_information"
            :min-price="extras.category_min_price"
            :present-link="extras.presentation_full_url"
            :link-url="values?.link_url"
            :link-title="values?.link_title"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';

export type BasicInformationAboutProjectBlockAdditionalInformation = {
	icon: number;
	message: string;
}
type BasicInformationAboutProjectBlockValues = {
	title?: string;
	subtitle?: string;
	description?: string;
	image?: number;
	image_mobile?: number;
	category_id?: number;
	additional_information?: BasicInformationAboutProjectBlockAdditionalInformation[];
	presentation?: number;
	link_url?: string;
	link_title?: string;
	form_title?: string;
	form_button_title?: string;
	form_reach_goal?: string;
}

type BasicInformationAboutProjectBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs' | 'extras'> & {
	values: BasicInformationAboutProjectBlockValues;
	cfgs: SectionTypes;
	extras: {
		presentation_full_url: string;
		category_min_price?: string;
	}
}
const { $customSectionClasses } = useNuxtApp();

const props = defineProps<BasicInformationAboutProjectBlockType>();

</script>

<style lang="scss">
@import "@/assets/scss/media";

.selector-block-description > * {
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 26px */
	letter-spacing: -0.4px;
  margin-top: 24px;
}

.selector-block-subtitle > * {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 26px */
  letter-spacing: -0.4px;
  color: var(--primary);
}

@include media('sm') {
	.selector-block-description > * {
		font-size: 16px;
		font-weight: 400;
		line-height: 135%; /* 21.6px */
		letter-spacing: -0.32px;
	}
}
</style>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.basicInfo-block {
	position: relative;
	overflow: hidden;
}

.basicInfo-block-content {
	display: flex;
	gap: 50px;

	& > .left {
		flex: 1;

		& > .h1 {
			font-size: 40px;
			font-weight: 600;
			line-height: 130%; /* 52px */
			letter-spacing: -1.6px;
			margin-bottom: 18px;
		}

		& > .subtitle {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: 135%; /* 27px */
			letter-spacing: -1px;
			padding: 28px 0;

			&::before,
			&::after {
				max-width: 100%;
				height: 2px;
			}
		}
	}

	& > .right {
		max-width: 410px;
		width: 100%;

		& > .price-list{
			padding: 32px 40px;
			border-radius: 10px;
			background: #f5f4f8;
			box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
			border: none;

			&:deep(.top){
				padding: 0;
				margin-bottom: 34px;
			}
		}
	}
}

.mobile-img {
	display: none;
	width: 152px;
	height: 152px;
	position: absolute;
	top: 10px;
	right: -20px;
	border-radius: 50%;
}

@include media('lg') {
	.mobile-img {
		display: block;

		& > img {
			height: 100%;
			width: 100%;
			object-fit: cover;
		}
	}

	.basicInfo-block-content {
		flex-direction: column;

		& > .right {
			max-width: initial;
			width: 100vw;
			margin-right: calc(-50vw + 50%);
			margin-left: calc(-50vw + 50%);

			& > .price-list{
				border-radius: initial;
				box-shadow: none;
			}
		}

		& > .left {
			width: 100%;

			& > .subtitle {
				&::before,
				&::after {
					max-width: 60%;
				}
			}
		}
	}
}

@include media('sm') {
  .selector-block-description {
    margin-bottom: 20px;
  }

	.basicInfo-block-content {
		& > .left {
			width: 100%;

			& > .h1 {
				font-size: 26px;
				font-style: normal;
				font-weight: 700;
				line-height: 115%; /* 29.9px */
			}

			& > .subtitle {
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: 120%; /* 24px */
				letter-spacing: -1px;
			}
		}
	}
}

</style>
